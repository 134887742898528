import React from 'react'
import {
  Breadcrumbs,
  Hero,
  Page,
  Impressum,
} from '@components'
import { buildLocalizedPath } from '@utils/functions'

const meta = {
  title: 'Impressum | Protupožarna zaštita - FSB d.o.o.',
  description: 'FSB d.o.o. obvezuje se na zaštitu vaših osobnih podatka i zadržava ih samo onoliko dugo koliko su potrebni.',
}

const ImpressumPage = ({ pageContext }) => (
  <Page
    locale={pageContext.locale}
    meta={meta}
  >
    <Breadcrumbs
      links={[
        { link: buildLocalizedPath('/', pageContext.locale), name: 'header.link.home'},
        { link: buildLocalizedPath('/impressum/', pageContext.locale), name: 'impressum.meta.title'},
      ]}
    />
    <Hero
      title=""
      subtitle="impressum.meta.title"
      description=""
    />
    <Impressum />
  </Page>
)

export default ImpressumPage